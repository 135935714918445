<template>
<TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="isOpen = false" class="relative z-10">
        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-black bg-opacity-75" />
        </TransitionChild>

        <div class="fixed  inset-0 overflow-y-auto">
            <div class="flex font-base mt-5  ml-96 -mr-96 items-center justify-center p-4 text-center">
                <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                    <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">

                        <div class="flex justify-between text-xl items-center">
                            <h3>Notifications</h3>
                            <span class="hover:bg-[#EDEDF3] duration-500 transition-all rounded-lg px-1 py-1">
                                <svg @click="isOpen = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 cursor-pointer h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>

                        </div>

                        <div class="mt-5 text-sm">
                            <ul class="mt-5 flex flex-col gap-5">
                                <li v-for="notification in notifications" class="bg-[#EDEDF3] h-16 flex items-center gap-2 rounded-md">
                                    <img :src="baseURL+notification.sender_account.customer.image" class="h-10 w-10 ml-2 rounded object-fit" alt="">
                                    <div class="flex flex-col gap-2 mr-2">
                                        <div class="flex  text-neutral-600 text-xs justify-between">
                                            <h3>{{ notification.title }}</h3>
                                        </div>
                                        <div class="flex text-neutral-500 text-xs justify-between">
                                            <h3>{{ formatTime(notification.created_at) }}</h3>
                                            <h3>{{ formatDate(notification.created_at) }}</h3>
                                        </div>

                                    </div>
                                </li>

                            </ul>
                            <div class="flex justify-between mt-3 text-xs text-primary">
                                <button class="flex gap-2 items-center">Mark all as read <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M7 12l5 5l10 -10"></path>
                                        <path d="M2 12l5 5m5 -5l5 -5"></path>
                                    </svg></button>
                                <a href="" class="bg-primary text-white py-1.5 px-2 rounded-md">View all notifications</a>
                            </div>

                        </div>

                    </DialogPanel>
                </TransitionChild>
            </div>
        </div>
    </Dialog>
</TransitionRoot>
</template>

<script>
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/vue'
import {
    mapActions,
    mapGetters
} from 'vuex'
import moment from 'moment'
export default {
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
    },
    data() {
        return {
            isOpen: false,
            baseURL: process.env.VUE_APP_BASE_URL,
        }
    },
    computed: {
        ...mapGetters({
            getStoredNotifications: "getStoredNotifications"
        }),
        notifications() {
            return this.getStoredNotifications.reverse().splice(-8).reverse()
        }
    },
    methods: {
        ...mapActions({
            getAllNotifications: "getAllNotifications"
        }),

        init() {
            this.getAllNotifications({
                cb: () => {

                }
            })
        },
        formatDate(date) {
            return moment(date).format('MMM Do, YY')
        },
        formatTime(date) {
            return moment(date).format('h:mm a')
        },
        openModal() {
            this.isOpen = true
        },
        closeModal() {
            this.isOpen = false
        }
    },
    mounted() {
        this.emitter.on("openNotificationsModal", () => {
            this.init()
            this.openModal()
        })
    },
}
</script>
