<template>
<div class="flex flex-col h-screen overflow-hidden">
    <header class="w-full text-center border-b border-grey bg-white p-4">Some header</header>
    <main class="flex-1 overflow-y-scroll">
        <div class="min-h-screen">
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quaerat quos dignissimos doloremque enim necessitatibus accusamus dolorum aperiam, at tempora vel?</p>
        </div>
        
        
    </main>
    <footer class="w-full text-center border-t bg-white border-grey p-4">some footer</footer>
</div>
</template>
