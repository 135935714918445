<template>
<nav class="bg-white">
    <div class="flex justify-between items-center lg:mx-10">
        <a href="/" class="text-3xl font-extrabold text-primary">Union Bank</a>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="lg:hidden w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>

        <div class="hidden lg:flex gap-5 text-sm items-center">
            <a href="/" class="bg-purple-50 text-primary py-2 rounded-md px-4">Dashboard</a>
            <a href="/transactions" class="hover:bg-purple-50 hover:text-primary inline-flex items-center  duration-300 transition-all py-2 rounded-md px-4">Transactions</a>
            <a href="/payment/requests/" class="hover:bg-purple-50 hover:text-primary inline-flex items-center  duration-300 transition-all py-2 rounded-md px-4">Payment requests</a>
            <a href="/analytics/" class="hover:bg-purple-50 hover:text-primary inline-flex items-center  duration-300 transition-all py-2 rounded-md px-4">Analytics</a>
            <a href="/" class="hover:bg-purple-50 hover:text-primary inline-flex items-center  duration-300 transition-all py-2 rounded-md px-4">Cards</a>

            <a href="/" class="hover:bg-purple-50 hover:text-primary inline-flex items-center  duration-300 transition-all py-2 rounded-md px-4">Services</a>

        </div>
        <div class="hidden lg:flex gap-6 items-center">
            <Notifications/>
           <Options/>

            <svg @click="openNotificationsModal" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 cursor-pointer h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
            </svg>

            <div class="flex gap-3 items-center">

                <img :src="baseURL+user?.image" class="h-8 w-8 rounded-full object-cover" alt="">
            </div>

        </div>
    </div>
</nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Notifications from './Notifications.vue'
import Options from './Options.vue'

export default {
    name: 'Navbar',
    components: {
        Notifications,
        Options,
    },
    data() {
        return {
            baseURL: process.env.VUE_APP_BASE_URL,
        }
    },
    computed: {
        ...mapGetters({
            getStoredUser: "getStoredUser"
        }),
        user() {
            return this.getStoredUser
        }
    },
    methods: {
        ...mapActions({
            getUser: "getUsersMe",
        }),
        openNotificationsModal() {
            this.emitter.emit("openNotificationsModal")
        },
        init() {
            this.getUser({
                cb: () => {

                }
            })
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style>
html,
body {
    background-color: #EDEDF3;
}
</style>
