
import Api from '@/services/Api'
const state = {
  bank_accounts: [],
  pageNumber: 1,
  cards: [],
  transactions: [],
  payment_requests: [],
  notifications: [],
  favourite_accounts: []
}

const mutations = {
  SET_BANK_ACCOUNTS (state, payload) {
    state.bank_accounts = payload
  },
  SET_CARDS (state, payload) {
    state.cards = payload
  },
  SET_TRANSACTIONS (state, payload) {
    state.transactions = payload
  },
  SET_PAYMENT_REQUESTS (state, payload) {
    state.payment_requests = payload
  },
  SET_NOTIFICATIONS (state, payload) {
    state.notifications = payload
  },
  SET_FAVOURITE_ACCOUNTS (state, payload) {
    state.favourite_accounts = payload
  },
INCREASE_PAGE(state) {
    state.pageNumber++
  },
  DECREASE_PAGE(state) {
    state.pageNumber--
  }
}

const actions = {
    async getTransactionReceipt({ commit, state }, { id, setResult=true, cb }) {
        return await Api()
            .get(`transaction/receipt/?id=${id}`,
            { headers: {
                'Content-Type': 'application/json',
            },
                'responseType': 'blob'
            }
            )
            .then((response) => {
                if (setResult) {
                }
                if (cb) {
                    cb(response.data)
                }
                return response.data
            })
            .catch((error) => {
                return Promise.reject(error)
            })
      },

  async getTransactionCategoryAnalytics({ commit, state }, { setResult=true, cb }) {
    return await Api()
        .get(`category/analytics/`)
        .then((response) => {
            if (setResult) {
            }
            if (cb) {
                cb(response.data)
            }
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })
  },
  async getTransactionDateAnalytics({ commit, state }, { setResult=true, cb }) {
    return await Api()
        .get(`date/analytics/`)
        .then((response) => {
            if (setResult) {
            }
            if (cb) {
                cb(response.data)
            }
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })
  },
  async getTransactionTypeAnalytics({ commit, state }, { setResult=true, cb }) {
    return await Api()
        .get(`type/analytics/`)
        .then((response) => {
            if (setResult) {
            }
            if (cb) {
                cb(response.data)
            }
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })
  },
  async getTransactionMonthAnalytics({ commit, state }, { setResult=true, cb }) {
    return await Api()
        .get(`month/analytics/`)
        .then((response) => {
            if (setResult) {
            }
            if (cb) {
                cb(response.data)
            }
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })
  },
  async createBankAccount({ payload, cb }) {
    return await Api()
        .post('/bank/accounts/', payload)
        .then((response) => {
            if (cb) {
                cb(response.data)
            }
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })
},
  async getAllBankAccounts({ commit, state }, { setResult=true, cb }) {
    return await Api()
        .get(`/bank/accounts/?page=${state.pageNumber}`)
        .then((response) => {
            if (setResult) {
                commit('SET_BANK_ACCOUNTS', response.data.results)
            }
            if (cb) {
                cb(response.data)
            }
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })
},
async updateBankAccount({ uuid, payload, cb }) {
  return await Api()
      .put(`/bank/accounts/${uuid}/`, payload)
      .then((response) => {
          if (cb) {
              cb(response.data)
          }
          return response.data
      })
      .catch((error) => {
          return Promise.reject(error)
      })
},
async deleteBankAccount({ commit }, { uuid, cb }) {
  return await Api()
      .delete(`/bank/accounts/${uuid}/`)
      .then((response) => {
          if (cb) {
              cb(response.data)
          }
          return response.data
      })
      .catch((error) => {
          return Promise.reject(error)
      })
},

async createCard({ payload, cb }) {
  return await Api()
      .post('/cards/', payload)
      .then((response) => {
          if (cb) {
              cb(response.data)
          }
          return response.data
      })
      .catch((error) => {
          return Promise.reject(error)
      })
},
async getAllCards({ commit, state }, { setResult=true, cb }) {
  return await Api()
      .get(`/cards/?page=${state.pageNumber}`)
      .then((response) => {
          if (setResult) {
              commit('SET_CARDS', response.data.results)
          }
          if (cb) {
              cb(response.data)
          }
          return response.data
      })
      .catch((error) => {
          return Promise.reject(error)
      })
},
async updateCard({ uuid, payload, cb }) {
return await Api()
    .put(`/cards/${uuid}/`, payload)
    .then((response) => {
        if (cb) {
            cb(response.data)
        }
        return response.data
    })
    .catch((error) => {
        return Promise.reject(error)
    })
},
async deleteCard({ commit }, { uuid, cb }) {
return await Api()
    .delete(`/cards/${uuid}/`)
    .then((response) => {
        if (cb) {
            cb(response.data)
        }
        return response.data
    })
    .catch((error) => {
        return Promise.reject(error)
    })
},
async createTransaction({ commit, state }, { payload, cb, errorCb }) {
  return await Api()
      .post('/transactions/', payload)
      .then((response) => {
          if (cb) {
              cb(response.data)
          }
          return response.data
      })
      .catch((error) => {
          if(errorCb) {
            errorCb(error)
          }
      })
},
async getAllTransactions({ commit, state }, { setResult=true, cb }) {
  return await Api()
      .get(`/transactions/?page=${state.pageNumber}`)
      .then((response) => {
          if (setResult) {
              commit('SET_TRANSACTIONS', response.data.results)
          }
          if (cb) {
              cb(response.data)
          }
          return response.data
      })
      .catch((error) => {
          return Promise.reject(error)
      })
},
async updateTransaction({ uuid, payload, cb }) {
return await Api()
    .put(`/transactions/${uuid}/`, payload)
    .then((response) => {
        if (cb) {
            cb(response.data)
        }
        return response.data
    })
    .catch((error) => {
        return Promise.reject(error)
    })
},
async deleteTransaction({ commit }, { uuid, cb }) {
return await Api()
    .delete(`/transactions/${uuid}/`)
    .then((response) => {
        if (cb) {
            cb(response.data)
        }
        return response.data
    })
    .catch((error) => {
        return Promise.reject(error)
    })
},
async createPaymentRequest({ commit, state }, { payload, cb }) {
  return await Api()
      .post('/payment/requests/', payload)
      .then((response) => {
          if (cb) {
              cb(response.data)
          }
          return response.data
      })
      .catch((error) => {
          return Promise.reject(error)
      })
},
async getAllPaymentRequests({ commit, state }, { setResult=true, cb }) {
  return await Api()
      .get(`/payment/requests/?page=${state.pageNumber}`)
      .then((response) => {
          if (setResult) {
              commit('SET_PAYMENT_REQUESTS', response.data.results)
          }
          if (cb) {
              cb(response.data)
          }
          return response.data
      })
      .catch((error) => {
          return Promise.reject(error)
      })
},
async updatePaymentRequest({ uuid, payload, cb }) {
return await Api()
    .put(`/payment/requests/${uuid}/`, payload)
    .then((response) => {
        if (cb) {
            cb(response.data)
        }
        return response.data
    })
    .catch((error) => {
        return Promise.reject(error)
    })
},
async deletePaymentRequest({ commit }, { uuid, cb }) {
return await Api()
    .delete(`/payment/requests/${uuid}/`)
    .then((response) => {
        if (cb) {
            cb(response.data)
        }
        return response.data
    })
    .catch((error) => {
        return Promise.reject(error)
    })
},
async createNotification({ commit, state }, { payload, cb }) {
  return await Api()
      .post('/notifications/', payload)
      .then((response) => {
          if (cb) {
              cb(response.data)
          }
          return response.data
      })
      .catch((error) => {
          return Promise.reject(error)
      })
},
async getAllNotifications({ commit, state }, { setResult=true, cb }) {
  return await Api()
      .get(`/notifications/?page=${state.pageNumber}`)
      .then((response) => {
          if (setResult) {
              commit('SET_NOTIFICATIONS', response.data.results)
          }
          if (cb) {
              cb(response.data)
          }
          return response.data
      })
      .catch((error) => {
          return Promise.reject(error)
      })
},
async updateNotification({ uuid, payload, cb }) {
return await Api()
    .put(`/notifications/${uuid}/`, payload)
    .then((response) => {
        if (cb) {
            cb(response.data)
        }
        return response.data
    })
    .catch((error) => {
        return Promise.reject(error)
    })
},
async deleteNotification({ commit }, { uuid, cb }) {
return await Api()
    .delete(`/notifications/${uuid}/`)
    .then((response) => {
        if (cb) {
            cb(response.data)
        }
        return response.data
    })
    .catch((error) => {
        return Promise.reject(error)
    })
},
async createFavouriteAccount({ commit, state }, { payload, cb }) {
  return await Api()
      .post('/favourites/', payload)
      .then((response) => {
          if (cb) {
              cb(response.data)
          }
          return response.data
      })
      .catch((error) => {
          return Promise.reject(error)
      })
},
async getAllFavouriteAccounts({ commit, state }, { setResult=true, cb }) {
  return await Api()
      .get(`/favourites/?page=${state.pageNumber}`)
      .then((response) => {
          if (setResult) {
              commit('SET_FAVOURITE_ACCOUNTS', response.data.results)
          }
          if (cb) {
              cb(response.data)
          }
          return response.data
      })
      .catch((error) => {
          return Promise.reject(error)
      })
},
}
const getters = {
  getStoredBankAccounts: (state) => {
    return state.bank_accounts
},
getStoredFavouriteAccounts: (state) => {
  return state.favourite_accounts
},
getStoredCards: (state) => {
  return state.cards
},
getStoredTransactions: (state) => {
  return state.transactions
},
getStoredPaymentRequests: (state) => {
  return state.payment_requests
},
getStoredNotifications: (state) => {
  return state.notifications
},
getStoredTransactionCategoryAnalytics: (state) => {
  return state.transaction_category_analytics
},
}

const coreModule = {
    state,
    mutations,
    actions,
    getters
  }
export default coreModule
