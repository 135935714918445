import { createStore } from 'vuex'
import coreModule from './modules/core'
import authModule from './modules/auth'

export default createStore({
  modules: {
    coreModule,
    authModule
  }
})
