<template>
<div class="font-base">
    <Navigation class="hidden lg:block" />
    <NewCardModal />
    <TopUpModal />
    <WithdrawalModal />
    <BankTransferModal />
    <MobileTransferModal />
    <BuyAirtimeModal />
    <BuyGoodsModal />
    <PaybillModal />
    <PayFeesModal />
    <RequestPaymentModal />
    <!-- <Mobile class="lg:hidden" /> -->
    <FavouriteAccountModal />
    <TransactionDrawer />

    <div class="hidden mx-12 mt-3 gap-5 lg:flex">
        <div class="lg:w-3/4  rounded-md">
            <div class="grid lg:grid-cols-2 gap-5">
                <div class="bg-white rounded-lg h-56">
                    <div class="p-5">
                        <h3 class="text-neutral-400">Available Balance</h3>
                        <h3 class="text-4xl font-bold mt-5 text-neutral-800">KSH {{ bank_account?.balance }}</h3>
                        <div class="flex lg:justify-between mt-5">
                            <button @click="openTopUpModal" class="text-xs bg-primary inline-flex gap-2 items-center text-white px-4 py-1 rounded-full">

                                Top up
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                                    <path fill-rule="evenodd" d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z" clip-rule="evenodd" />
                                </svg>
                            </button>
                            <button @click="openWithdrawalModal" class="text-xs bg-[#ededf3] inline-flex gap-2 items-center text-neutral-900 px-4 py-2 rounded-full">

                                Withdraw
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
                                </svg>

                            </button>
                            <button @click="openRequestPaymentModal" class="text-xs bg-[#ededf3] inline-flex gap-2 items-center text-neutral-900 px-4 py-2 rounded-full">

                                Request
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25" />
                                </svg>
                            </button>

                        </div>
                        <p class="mt-5 text-neutral-500 inline-flex gap-4 items-center">Account number: <span class="text-neutral-900 font-extrabold">{{ bank_account?.account_number }}</span>
                            <svg v-if="!copied" @click="copyBankAccount(bank_account?.account_number)" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer text-primary" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
                                <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path>
                            </svg>
                            <small v-if="copied" class="inline-flex text-primary gap-2 items-center text-xs">Copied <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-primary">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                            </small>
                        </p>
                    </div>
                </div>
                <div class="bg-white rounded-lg h-56">
                    <div class="grid grid-cols-3 gap-5 mt-8">
                        <div class="flex flex-col gap-2 items-center justify-center">
                            <span @click="openMobileTransferModal" class="px-4 cursor-pointer py-4 rounded-full bg-secondary text-neutral-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                </svg>

                            </span>
                            <small class="text-neutral-500 text-xs uppercase">send to mobile</small>
                        </div>
                        <div class="flex flex-col gap-2 items-center justify-center">
                            <span @click="openBankTransferModal" class="px-4 cursor-pointer py-4 rounded-full bg-secondary text-neutral-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                </svg>

                            </span>
                            <small class="text-neutral-500 text-xs uppercase">bank transfer</small>
                        </div>
                        <div class="flex flex-col gap-2 items-center justify-center">
                            <span @click="openBuyAirtimeModal" class="px-4 cursor-pointer py-4 rounded-full bg-secondary text-neutral-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                                </svg>

                            </span>
                            <small class="text-neutral-500 text-xs uppercase">buy airtime</small>
                        </div>
                        <div class="flex flex-col gap-2 items-center justify-center">
                            <span @click="openBuyGoodsModal" class="px-4 cursor-pointer py-4 rounded-full bg-secondary text-neutral-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                                </svg>

                            </span>
                            <small class="text-neutral-500 text-xs uppercase">buy goods</small>
                        </div>
                        <div class="flex flex-col gap-2 items-center justify-center">
                            <span @click="openPaybillModal" class="px-4 cursor-pointer py-4 rounded-full bg-secondary text-neutral-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                </svg>

                            </span>
                            <small class="text-neutral-500 text-xs uppercase">paybill</small>
                        </div>
                        <div class="flex flex-col gap-2 items-center justify-center">
                            <span @click="openPayFeesModal" class="px-4 cursor-pointer py-4 rounded-full bg-secondary text-neutral-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                </svg>

                            </span>
                            <small class="text-neutral-500 text-xs uppercase">pay fees</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex gap-5 rounded-lg mt-3">
                <div class="bg-white w-2/3 rounded-lg">
                    <div class="p-5 ">
                        <h3 class="text-neutral-500">Favourites</h3>
                        <div class="flex gap-5 mt-5">
                            <div @click="openFavouriteAccountModal" class="flex cursor-pointer flex-col gap-2 items-center justify-center">
                                <span class="px-2 cursor-pointer py-2 rounded-full border border-dashed border-[#6c448d] text-[#6c448d]">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                                        <path fill-rule="evenodd" d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <small class="text-primary">Add</small>
                            </div>
                            <div v-for="favourite in favourite_accounts" class="flex flex-col gap-2 items-center justify-center">
                                <img class="w-10 h-10 object-cover rounded-full" :src="baseURL+favourite.favourite_account.customer.image" alt="">
                                <small class="text-neutral-500">{{ favourite.favourite_account?.customer.full_name }}</small>

                            </div>

                        </div>

                    </div>

                </div>
                <div class="rounded-lg p-5 w-1/3 bg-gradient-to-r flex flex-col gap-6 from-primary to-indigo-500">
                    <h3 class="text-white text-sm">Invite family & friends via the code below & earn up to KSH 5000 when they make their first transaction. </h3>
                    <span class="bg-black/40 text-xs text-white py-1 px-4 flex justify-between items-center rounded-full">0XTOCT-YRT6XX-OCT-2023

                        <svg xmlns="http://www.w3.org/2000/svg" class="cursor-pointer " width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
                            <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="bg-white  rounded-md mt-3">
                <div class="p-3">
                    <div class="flex text-sm text-neutral-500 items-center justify-between mx-1">
                        <h3 class=" font-bold">Recent Transactions</h3>
                        <a href="/transactions" class="text-primary">See all</a>
                    </div>

                    <div v-if="transactions.length" class="relative mt-2 overflow-x-auto rounded-md">
                        <table class="w-full text-xs text-left text-neutral-500">
                            <thead class="text-xs text-neutral-700 uppercase bg-[#EDEDF3] rounded-md">
                                <tr>
                                    <th scope="col" class="px-6 py-3">
                                        recipient
                                    </th>
                                    <th scope="col" class="px-6 py-2">
                                        sender
                                    </th>
                                    <th scope="col" class="px-6 py-2">
                                        date
                                    </th>
                                    <th scope="col" class="px-6 py-2">
                                        type
                                    </th>
                                    <th scope="col" class="px-6 py-2">
                                        reference
                                    </th>
                                    <th scope="col" class="px-6 py-2">
                                        amount
                                    </th>
                                    <th scope="col" class="px-6 py-2">
                                        view
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="transaction in transactions" class="bg-white border-b">
                                    <th scope="row" class="px-2 flex items-center gap-4 py-2 whitespace-nowrap">
                                        <div class="first:bg-primary/20 text-primary h-10 w-10 rounded-full flex items-center justify-center">
                                            {{ transaction.recipient_initials }}
                                        </div>
                                        <div>
                                            <p v-if="transaction.internal_recipient_account.customer != null" class="text-neutral-900">{{ transaction.internal_recipient_account.customer.full_name }}</p>
                                            <p v-else class="text-neutral-900">{{ transaction.external_recipient_account }}</p>
                                            <small v-if="transaction.internal_recipient_account.customer != null" class="text-neutral-500">{{ transaction.recipient_account_number }}</small>
                                            <small v-else class="text-neutral-500">{{ transaction.external_recipient_account }}</small>

                                        </div>
                                    </th>
                                    <td class="px-6 py-2">
                                        <p class="text-neutral-900">
                                            {{ transaction.sender_account.customer.full_name }} <br>
                                            <small class="text-neutral-500"> {{ transaction.sender_account_number }}</small>
                                        </p>
                                    </td>
                                    <td class="px-6 py-2">
                                        <p class="text-neutral-900">{{ formatDate(transaction.created_at) }}</p>
                                        <small class="text-neutral-500 uppercase">{{ formatTime(transaction.created_at) }}</small>
                                    </td>
                                    <td class="px-6 py-2">
                                        <p v-if="transaction.category === 'Bank transfer' && user?.full_name === transaction.internal_recipient_account?.customer.full_name" class="text-neutral-900">
                                            Deposit </p>
                                        <p v-else class="text-neutral-900">
                                            {{ transaction.type }} </p>
                                        <small class="text-neutral-500"> {{ transaction.category }}</small>

                                    </td>
                                    <td class="px-6 py-2">
                                        <p>{{ transaction.transaction_reference }}
                                            <br>
                                            <small class="text-green-500">{{ transaction.status }}</small></p>
                                    </td>
                                    <td class="px-6 py-2">
                                        <p v-if="transaction.category === 'Bank transfer' && user?.full_name === transaction.internal_recipient_account?.customer.full_name" class="text-green-500"> + {{ transaction.amount }}</p>
                                        <p v-if="transaction.category === 'Bank transfer' && user?.full_name !== transaction.internal_recipient_account?.customer.full_name" class="text-red-500"> - {{ transaction.amount }}</p>
                                        <p v-if="transaction.category === 'Top up'" class="text-green-500"> + {{ transaction.amount }}</p>
                                        <p v-if="transaction.category === 'Agent withdrawal' | transaction.category === 'Airtime purchase' | transaction.category === 'Buy goods' | transaction.category === 'Mobile transfer' | transaction.category === 'Paybill' | transaction.category === 'Pay fees'" class="text-red-500"> - {{ transaction.amount }}</p>
                                    </td>
                                    <td class="px-6 py-2">
                                        <svg @click=" openTransactionDrawer(transaction)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 cursor-pointer h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>

                                    </td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div v-else class="text-neutral-400 flex items-center justify-center mt-5">
                        <h3>You don't have any recent transactions.</h3>
                    </div>

                </div>

            </div>
        </div>
        <div class="w-1/3 hidden lg:flex flex-col gap-5 rounded-md">
            <div class="bg-gradient-to-r from-primary via-purple-950 to-[#101515] text-white rounded-xl h-56">
                <div class="flex items-center justify-between mx-5">
                    <h3 class="text-xs uppercase">{{ card?.type }}</h3>
                    <svg v-if="card?.provider === 'Visa'" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-visa" width="52" height="52" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M21 15l-1 -6l-2.5 6"></path>
                        <path d="M9 15l1 -6"></path>
                        <path d="M3 9h1v6h.5l2.5 -6"></path>
                        <path d="M16 9.5a.5 .5 0 0 0 -.5 -.5h-.75c-.721 0 -1.337 .521 -1.455 1.233l-.09 .534a1.059 1.059 0 0 0 1.045 1.233a1.059 1.059 0 0 1 1.045 1.233l-.09 .534a1.476 1.476 0 0 1 -1.455 1.233h-.75a.5 .5 0 0 1 -.5 -.5"></path>
                        <path d="M18 14h2.7"></path>
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-mastercard" width="52" height="52" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M14 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                        <path d="M12 9.765a3 3 0 1 0 0 4.47"></path>
                        <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
                    </svg>
                </div>
                <div class="mt-2 flex flex-col gap-1 mx-5  text-2xl">
                    <div class="flex justify-between">
                        <img src="../assets/chip.png" class="w-8 h-8" alt="">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 rotate-90 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.288 15.038a5.25 5.25 0 017.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 011.06 0z" />
                        </svg>

                    </div>

                    <h3 v-if="hideDetails" class="mt-5">**** **** **** ****</h3>
                    <h3 v-else class="mt-5">{{ card?.card_number }}</h3>
                    <div class="flex justify-between items-center mr-5 mt-3">
                        <h3 class="mt-2">{{ card?.account.customer?.first_name }} {{ card?.account.customer?.last_name }}</h3>
                        <div class="flex text-xs gap-10">
                            <h3>Valid Thru <br>
                                07/25</h3>

                            <h3 v-if="hideDetails">CVV <br>
                                ***</h3>
                            <h3 v-else>CVV <br>
                                {{ card?.cvc }}</h3>
                        </div>
                    </div>

                </div>
            </div>
            <div class="grid grid-cols-4 gap-5 rounded-lg">
                <button v-if="!hideDetails" @click="hideDetails = true" class="bg-white text-xs py-1.5 rounded-md">Hide details</button>
                <button v-if="hideDetails" @click="hideDetails = false" class="bg-white text-xs py-1.5 rounded-md">Show details</button>
                <button @click="openNewCardModal" class="bg-white text-xs py-1.5 rounded-md">New card</button>
                <button class="bg-white text-xs py-1.5 rounded-md">Edit limits</button>
                <button class="bg-white text-xs py-1.5 rounded-md">Report as lost</button>
            </div>
            <div class="bg-white rounded-lg">
                <div class="p-3">
                    <div class="flex justify-between">
                        <h3 class="text-lg">Card subscriptions</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-primary">
                            <path fill-rule="evenodd" d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <ul class="mt-2 flex flex-col gap-5">
                        <li class="bg-[#EDEDF3]/30 h-16 flex justify-between items-center gap-2 rounded-md">
                            <div class="flex gap-2 items-center">
                                <span class="px-1 cursor-pointer py-1 ml-2 rounded-full bg-green-100 text-neutral-600">

                                    <img src="../assets/spotify.svg" class="h-10 w-10" alt="">
                                </span>

                                <div class="flex flex-col text-neutral-900 text-xs justify-between">
                                    <h3>Spotify premium</h3>
                                    <small>nick@tech.com</small>
                                </div>
                            </div>
                            <div class="flex  text-neutral-900 text-xs justify-between">
                                <h3>Oct 12, 2023 <br> 10:05 AM</h3>
                            </div>
                            <div class="flex mr-2 text-neutral-900 text-xs justify-between">
                                <h3>KSH 500 <br> monthly</h3>
                            </div>

                        </li>
                        <li class="bg-[#EDEDF3]/30 h-16 flex justify-between items-center gap-2 rounded-md">
                            <div class="flex gap-2 items-center">
                                <span class="px-1 cursor-pointer py-1 ml-2 rounded-full bg-amber-100 text-neutral-600">

                                    <img src="../assets/aws.svg" class="h-10 w-10" alt="">
                                </span>

                                <div class="flex flex-col text-neutral-900 text-xs justify-between">
                                    <h3>AWS enterprise</h3>
                                    <small>nick@tech.com</small>
                                </div>
                            </div>
                            <div class="flex  text-neutral-900 text-xs justify-between">
                                <h3>Oct 9, 2023 <br> 12:00 PM</h3>
                            </div>
                            <div class="flex mr-2 text-neutral-900 text-xs justify-between">
                                <h3>KSH 1,100 <br> monthly</h3>
                            </div>

                        </li>
                        <li class="bg-[#EDEDF3]/30 h-16 flex justify-between items-center gap-2 rounded-md">
                            <div class="flex gap-2 items-center">
                                <span class="px-1 cursor-pointer py-1 ml-2 rounded-full bg-pink-100 text-neutral-600">

                                    <img src="../assets/adobe.svg" class="h-10 w-10" alt="">
                                </span>

                                <div class="flex flex-col text-neutral-900 text-xs justify-between">
                                    <h3>Adobe premium</h3>
                                    <small>nick@tech.com</small>
                                </div>
                            </div>
                            <div class="flex  text-neutral-900 text-xs justify-between">
                                <h3>Jan 5, 2023 <br> 00:00 PM</h3>
                            </div>
                            <div class="flex mr-2 text-neutral-900 text-xs justify-between">
                                <h3>KSH 2,000 <br> yearly</h3>
                            </div>

                        </li>

                        <li class="bg-[#EDEDF3]/30 h-16 flex justify-between items-center gap-2 rounded-md">
                            <div class="flex gap-2 items-center">
                                <span class="px-1 cursor-pointer py-1 ml-2 rounded-full bg-red-100 text-neutral-600">

                                    <img src="../assets/shell.svg" class="h-10 w-10" alt="">
                                </span>

                                <div class="flex flex-col text-neutral-900 text-xs justify-between">
                                    <h3>Shell service</h3>
                                    <small>nick@tech.com</small>
                                </div>
                            </div>
                            <div class="flex  text-neutral-900 text-xs justify-between">
                                <h3>June 1, 2023 <br> 08:00 AM</h3>
                            </div>
                            <div class="flex mr-2 text-neutral-900 text-xs justify-between">
                                <h3>KSH 5,600 <br> quaterly</h3>
                            </div>

                        </li>
                        <button class="bg-primary text-xs text-white py-2 rounded-md">Manage card subscriptions</button>

                    </ul>
                </div>
            </div>

        </div>
    </div>

</div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import NewCardModal from '@/components/NewCardModal.vue'
import TopUpModal from '@/components/TopUpModal.vue'
import {
    mapActions,
    mapGetters
} from 'vuex'
import moment from "moment"
import WithdrawalModal from '@/components/WithdrawalModal.vue'
import BankTransferModal from '@/components/BankTransferModal.vue'
import MobileTransferModal from '@/components/MobileTransferModal.vue'
import BuyGoodsModal from '@/components/BuyGoodsModal.vue'
import BuyAirtimeModal from '@/components/BuyAirtimeModal.vue'
import PayFeesModal from '@/components/PayFeesModal.vue'
import PaybillModal from '@/components/PaybillModal.vue'
import RequestPaymentModal from '@/components/RequestPaymentModal.vue'
import Mobile from './Mobile.vue'
import FavouriteAccountModal from '@/components/FavouriteAccountModal.vue'
import TransactionDrawer from '@/components/TransactionDrawer.vue'

export default {
    name: 'Home',
    components: {
        TransactionDrawer,
        Mobile,
        Navigation,
        NewCardModal,
        TopUpModal,
        WithdrawalModal,
        BankTransferModal,
        MobileTransferModal,
        BuyAirtimeModal,
        BuyGoodsModal,
        PayFeesModal,
        PaybillModal,
        RequestPaymentModal,
        FavouriteAccountModal,
        TransactionDrawer
    },
    data() {
        return {
            hideDetails: false,
            baseURL: process.env.VUE_APP_BASE_URL,
            copied: false,
        }
    },
    computed: {
        ...mapGetters({
            getStoredBankAccounts: "getStoredBankAccounts",
            getStoredCards: "getStoredCards",
            getStoredTransactions: "getStoredTransactions",
            getStoredUser: "getStoredUser",
            getStoredNotifications: "getStoredNotifications",
            getStoredFavouriteAccounts: "getStoredFavouriteAccounts",
        }),
        bank_account() {
            return this.getStoredBankAccounts[0]
        },
        card() {
            return this.getStoredCards[0]
        },
        transactions() {
            return this.getStoredTransactions.reverse().splice(-4).reverse()
        },
        user() {
            return this.getStoredUser
        },
        favourite_accounts() {
            return this.getStoredFavouriteAccounts
        },

    },
    methods: {
        ...mapActions({
            getAllBankAccounts: "getAllBankAccounts",
            getAllCards: "getAllCards",
            getAllTransactions: "getAllTransactions",
            getUser: "getUsersMe",
            getAllNotifications: "getAllNotifications",
            getAllFavouriteAccounts: "getAllFavouriteAccounts",
        }),

        init() {
            this.getAllBankAccounts({
                cb: () => {

                }
            })
            this.getAllCards({
                cb: () => {

                }
            })
            this.getAllTransactions({
                cb: () => {

                }
            })
            this.getUser({
                cb: () => {

                }
            })
            this.getAllFavouriteAccounts({
                cb: () => {

                }
            })
        },
        openNewCardModal() {
            this.emitter.emit("openNewCardModal")
        },
        openTopUpModal() {
            this.emitter.emit("openTopUpModal")
        },
        openWithdrawalModal() {
            this.emitter.emit("openWithdrawalModal")
        },
        openBankTransferModal() {
            this.emitter.emit("openBankTransferModal")
        },
        openMobileTransferModal() {
            this.emitter.emit("openMobileTransferModal")
        },
        openBuyAirtimeModal() {
            this.emitter.emit("openBuyAirtimeModal")
        },
        openBuyGoodsModal() {
            this.emitter.emit("openBuyGoodsModal")
        },
        openPaybillModal() {
            this.emitter.emit("openPaybillModal")
        },
        openPayFeesModal() {
            this.emitter.emit("openPayFeesModal")
        },
        openRequestPaymentModal() {
            this.emitter.emit("openRequestPaymentModal")
        },
        openFavouriteAccountModal() {
            this.emitter.emit("openFavouriteAccountModal")
        },
        openTransactionDrawer(transaction) {
            this.emitter.emit("openTransactionDrawer", transaction)
        },
        formatDate(date) {
            return moment(date).format('MMM Do, YY')
        },
        formatTime(date) {
            return moment(date).format('h:mm a')
        },
        unsecuredCopyToClipboard(text) {
            const textArea = document.createElement("textarea")
            textArea.value = text
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()
            try {
                document.execCommand('copy')
            } catch (err) {
                console.error('Unable to copy to clipboard', err)
            }
            document.body.removeChild(textArea)
        },
        copyBankAccount(account) {
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(account)
            } else {
                this.unsecuredCopyToClipboard(account)
            }
            this.copied = true
            setTimeout(() => this.copied = false, 2000)
        },
    },
    mounted() {
        this.init()
        this.emitter.on("refreshAccount", () => {
            this.init()
        })
    }
}
</script>

<style>
html,
body {
    background-color: #EDEDF3;
}
</style>
