<template>
<TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-black bg-opacity-50" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
            <div class="flex h-full items-center justify-center p-4 text-center">
                <TransitionChild as="template" enter='transform transition ease-in-out duration-500 sm:duration-700' enterFrom='translate-x-full' enterTo='translate-x-0' leave='transform transition ease-in-out duration-500 sm:duration-700' leaveFrom='translate-x-0' leaveTo='translate-x-full'>
                    <DialogPanel class="fixed w-1/3 top-0 right-0 z-40 font-base h-screen rounded-md transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <div class="flex justify-between items-center mx-2 text-neutral-500">
                            <span @click="closeModal" class="hover:bg-[#EDEDF3] duration-500 transition-all cursor-pointer rounded-lg px-1 py-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                                </svg>
                            </span>
                            <h3 class="uppercase text-neutral-950">details</h3>
                            <span @click="closeModal" class="hover:bg-[#EDEDF3] duration-500 transition-all cursor-pointer rounded-lg px-1 py-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>

                        </div>
                        <div class="p-5 text-sm">

                            <div class="flex flex-col gap-3">
                                <div class="flex flex-col gap-2">
                                    <h3 class="uppercase text-neutral-500">transaction amount</h3>
                                    <h3 class="text-neutral-900">{{ transaction?.amount }}</h3>
                                </div>
                                <div class="flex flex-col gap-2 border-b border-dashed py-2">
                                    <h3 class="uppercase text-neutral-500">transaction status</h3>
                                    <h3 class="text-emerald-400">{{ transaction?.status }}</h3>
                                </div>
                                <div class="flex flex-col gap-2">
                                    <h3 class="uppercase text-neutral-500">transaction date</h3>
                                    <h3 class="text-neutral-900">{{ formatDate(transaction?.created_at) }}</h3>
                                </div>
                                <div class="flex flex-col gap-2">
                                    <h3 class="uppercase text-neutral-500">transaction time</h3>
                                    <h3 class="text-neutral-900">{{ formatTime(transaction?.created_at) }}</h3>
                                </div>
                                <div class="flex flex-col gap-2 border-b border-dashed py-2">
                                    <h3 class="uppercase text-neutral-500">transaction reference</h3>
                                    <h3 class="text-neutral-900">{{ transaction?.transaction_reference }}</h3>
                                </div>
                                <div class="flex flex-col gap-2">
                                    <h3 class="uppercase text-neutral-500">transaction sender</h3>
                                    <h3 class="text-neutral-900">{{ transaction?.sender_account.customer.full_name }}</h3>
                                </div>
                                <div class="flex flex-col gap-2 border-b border-dashed py-2">
                                    <h3 class="uppercase text-neutral-500">transaction sender account</h3>
                                    <h3 class="text-neutral-900">{{ transaction?.sender_account_number }}</h3>
                                </div>
                                <div v-if="transaction.internal_recipient_account.customer != null" class="flex flex-col gap-2">
                                    <h3 class="uppercase text-neutral-500">transaction recipient</h3>
                                    <h3 class="text-neutral-900">{{ transaction?.internal_recipient_account.customer.full_name }}</h3>
                                </div>
                                <div v-else class="flex flex-col gap-2">
                                    <h3 class="uppercase text-neutral-500">transaction recipient</h3>
                                    <h3 class="text-neutral-900">{{ transaction?.external_recipient_account }}</h3>
                                </div>
                                <div v-if="transaction.internal_recipient_account.customer != null" class="flex flex-col gap-2 border-b border-dashed py-2">
                                    <h3 class="uppercase text-neutral-500">transaction recipient account</h3>
                                    <h3 class="text-neutral-900">{{ transaction?.recipient_account_number }}</h3>
                                </div>
                                <div v-else class="flex flex-col gap-2 border-b border-dashed py-2">
                                    <h3 class="uppercase text-neutral-500">transaction recipient account</h3>
                                    <h3 class="text-neutral-900">{{ transaction?.external_recipient_account }}</h3>
                                </div>
                            </div>
                            <button @click="downloadTransactionReceipt(transaction)" class="bg-primary focus:outline-none focus:border-0 focus:ring-0 text-white w-full py-2 mt-5 uppercase rounded-md inline-flex gap-3 items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-download" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
                                    <path d="M12 17v-6"></path>
                                    <path d="M9.5 14.5l2.5 2.5l2.5 -2.5"></path>
                                </svg>
                                download receipt</button>

                        </div>

                    </DialogPanel>
                </TransitionChild>
            </div>
        </div>
    </Dialog>
</TransitionRoot>
</template>

<script>
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/vue'
import moment from 'moment';
import {
    mapActions
} from 'vuex';

export default {
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
    },
    data() {
        return {
            isOpen: false,
            transaction: null,
        }
    },
    methods: {
        ...mapActions({
            getTransactionReceipt: "getTransactionReceipt"
        }),

        closeModal() {
            this.isOpen = false
        },

        openModal() {
            this.isOpen = true
        },
        formatDate(date) {
            return moment(date).format('MMM Do, YY')
        },
        formatTime(date) {
            return moment(date).format('h:mm a')
        },
        downloadTransactionReceipt(transaction) {
            this.getTransactionReceipt({
                id: transaction.id,
                cb: (response) => {
                    this.downloadFile(response, 'transaction-receipt.pdf', 'application/pdf')
                }
            })
        },
        downloadFile(data, name, type) {
            const url = window.URL.createObjectURL(new Blob([data], {
                type
            }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', name)
            document.body.appendChild(link)
            link.click()
            setTimeout(() => {
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url)
            }, 200)
        }
    },
    mounted() {
        this.emitter.on("openTransactionDrawer", (data) => {
            this.transaction = data
            this.openModal()
        })
    }
}
</script>
