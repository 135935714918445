<template>
<TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="isOpen = false" class="relative z-10">
        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-black bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
            <div class="flex font-base min-h-full items-center justify-center p-4 text-center">
                <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                    <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">

                        <div class="flex justify-between text-xl items-center">
                            <h3>Bank transfer request</h3>
                            <span class="hover:bg-[#EDEDF3] duration-500 transition-all rounded-lg px-1 py-1">
                                <svg @click="isOpen = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 cursor-pointer h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>

                        </div>
                        <div v-if="error" class="bg-red-50 mt-5 rounded-full py-1.5 w-full text-red-500 text-xs">
                            <small class="ml-4">{{ errorMessage }}</small>
                        </div>

                        <div class="mt-5 text-sm">
                            <div v-if="success">
                                <div class="bg-primary/25 h-20 flex items-center justify-center rounded-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon text-primary icon-tabler icon-tabler-circle-check-filled" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" stroke-width="0" fill="currentColor"></path>
                                    </svg>
                                </div>
                                <p class="mt-4 text-neutral-500">Your transaction was successful.</p>
                                <p class="mt-4 text-neutral-500 inline-flex gap-4 items-center">Transaction reference: <span class="text-neutral-900 font-extrabold">{{ reference }}</span> <svg xmlns="http://www.w3.org/2000/svg" class="cursor-pointer text-primary" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
                                        <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path>
                                    </svg> </p>
                                <button type="button" class="inline-flex justify-center mt-5 rounded-md bg-primary text-white px-4 py-2 text-xs w-full font-medium border focus:outline-none" @click="closeModal">
                                    Ok, got it.
                                </button>
                            </div>

                            <form v-else class="flex text-neutral-600 text-xs flex-col gap-5">
                                <div class="flex text-xs flex-col gap-3">
                                    <label for="amount">Sender Account</label>
                                    <select v-model="account" class="border border-neutral-300 focus:border-primary text-xs py-2 rounded-md focus:outline-none focus:ring-0 pl-4">
                                        <option value="" class="text-xs">Select account</option>
                                        <option class="text-xs" v-for="account in bank_accounts" :value="account.id">{{ account.account_number }}</option>
                                    </select>
                                </div>
                                <div class="flex flex-col gap-3">
                                    <label for="amount">Recipient account</label>
                                    <input v-model="recipient_account" type="text" class="border border-neutral-300 focus:border-primary py-2  text-xs rounded-md focus:outline-none focus:ring-0 pl-4">
                                </div>
                                <div class="flex flex-col gap-3">
                                    <label for="amount">Amount to transfer</label>
                                    <input v-model="amount" type="number" class="border border-neutral-300 focus:border-primary py-2 text-xs rounded-md focus:outline-none focus:ring-0 pl-4">
                                </div>
                            </form>

                        </div>

                        <div v-if="!success" class="mt-4 text-xs flex justify-between">
                            <button type="button" class="inline-flex justify-center rounded-md bg-[#EDEDF3] items-center text-neutral-500 px-4 py-2  font-medium border focus:outline-none" @click="closeModal">
                                Cancel operation
                            </button>
                            <button @click="submitBankTransferRequest" type="button" class="inline-flex justify-center items-center rounded-md bg-primary px-4 py-2 font-medium text-white focus:outline-none">
                                Transfer funds
                            </button>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </div>
    </Dialog>
</TransitionRoot>
</template>

<script>
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/vue'
import {
    mapActions,
    mapGetters
} from 'vuex'

export default {
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
    },
    data() {
        return {
            isOpen: false,
            account: "",
            amount: "",
            type: "Withdrawal",
            category: "Bank transfer",
            agent_number: "",
            success: false,
            reference: "",
            recipient_account: "",
            error: false,
            errorMessage: ""
        }
    },
    computed: {
        ...mapGetters({
            getStoredBankAccounts: "getStoredBankAccounts"
        }),
        bank_accounts() {
            return this.getStoredBankAccounts
        }
    },
    methods: {
        ...mapActions({
            getAllBankAccounts: "getAllBankAccounts",
            createTransaction: "createTransaction"
        }),

        init() {
            this.getAllBankAccounts({
                cb: () => {

                }
            })
        },
        resetForm() {
            this.amount = ""
            this.account = ""
        },
        submitBankTransferRequest() {
            this.createTransaction({
                payload: {
                    "sender_account": this.account,
                    "amount": this.amount,
                    "type": this.type,
                    "category": this.category,
                    "internal_recipient_account": this.recipient_account,
                    "external_recipient_account": "",
                    "agent_number": this.agent_number
                },
                cb: (res) => {
                    this.success = true
                    this.reference = res.transaction_reference
                },
                errorCb: (error) => {
                    this.error = true
                    this.errorMessage = this.cleanErrorMessage(error.response.data.error.message)
                    setTimeout(() => this.error = false, 2000)
                }
            })
        },
        cleanErrorMessage(input) {
            let forbiddenChars = ['[', ']', "'"]

            for (let char of forbiddenChars) {
                input = input.split(char).join('');
            }
            return input
        },
        openModal() {
            this.isOpen = true
        },
        closeModal() {
            this.isOpen = false
            this.success = false
            this.emitter.emit("refreshAccount")
            this.resetForm()
        }
    },
    mounted() {
        this.init()
        this.emitter.on("openBankTransferModal", () => {
            this.openModal()
        })
    },

}
</script>
