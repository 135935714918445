import axios from 'axios'

export default (content_type = 'application/json') => {
    
    axios.defaults.withCredentials = true
    const Api = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        headers: {
            Accept: 'application/json',
            'Content-Type': content_type,
        },
    })
    return Api
}
